import React, { useState } from "react"
import TextArea from "../../components/elements/TextArea"
import Button from "../../components/elements/Button"
import copy from "copy-to-clipboard"
import NotificationUtils from "../../utils/notification"
import { H1, H2 } from "../../components/elements/Headings"
import {
  PageTitleContainer,
  ContentContainer,
  UtilityButtonContainer,
} from "../../components/layout/Containers"
import SEO from "../../components/seo"
import Layout from "../../components/layout/Layout"
import { encode } from "../../services/base64"

const Base64Encode = () => {
  const [stringValue, setStringValue] = useState("")
  const [encodedValue, setEncodedValue] = useState("")

  const encodeValue = () => {
    if (stringValue && stringValue.trim().length > 0) {
      let encodedString: string
      try {
        encodedString = encode(stringValue)
        setEncodedValue(encodedString)
      } catch (_) {
        NotificationUtils.error({
          title: "Error",
          description: "Error while deconding the string!",
        })
      }
    } else {
      reset()
    }
  }

  const reset = () => {
    setStringValue("")
    setEncodedValue(undefined)
  }

  const copyToClipboard = () => {
    copy(encodedValue)
    NotificationUtils.default({
      title: "Copied!",
      description: "Content copied to your clipboard",
    })
  }

  return (
    <>
      <SEO title="Base64 Endcode" />
      <Layout>
        <PageTitleContainer>
          <H1>Base64 Encode</H1>
          <H2>Paste your string here and press the button!</H2>
        </PageTitleContainer>
        <ContentContainer>
          <TextArea
            onChange={setStringValue}
            value={stringValue}
            rows={10}
            placeholder={"The string you want to encode..."}
            className="self-start"
          />
          <UtilityButtonContainer>
            <Button click={encodeValue} primary>
              Encode
            </Button>
            <Button click={reset} accent>
              Reset
            </Button>
          </UtilityButtonContainer>
        </ContentContainer>
        {encodedValue && (
          <div className="px-4">
            <TextArea
              readOnly={true}
              rows={10}
              value={encodedValue}
              placeholder={"The encoded string..."}
              className="bg-gray-400"
            />
            <UtilityButtonContainer>
              <Button click={copyToClipboard} secondary>
                Copy to clipboard
              </Button>
            </UtilityButtonContainer>
          </div>
        )}
      </Layout>
    </>
  )
}

export default Base64Encode
